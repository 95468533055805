<template>
  <div class="tier-package-cont">
    <vs-card>
      <div slot="header">
        <vs-row vs-type="flex" vs-align="space-around" vs-w="12">
          <vs-col vs-w="6">
            <div class="pt-2 animate-pulse w-32" v-if="isFirstLoad">
              <div class="h-2 rounded bg-gray-400"></div>
            </div>
            <h5 class="pt-2" v-else>{{ mode }} tier package</h5>
          </vs-col>
          <vs-col vs-w="6">
            <div v-if="isFirstLoad"
              class="flex items-center justify-end h-6">
              <div class="animate-pulse w-24">
                <div class="grid grid-cols-3 gap-4">
                  <div class="col-span-2 h-2 rounded bg-gray-400"></div>
                  <div class="col-span-1 h-2 rounded bg-gray-400"></div>
                </div>
              </div>
            </div>
            <ul class="block text-right" v-else>
              <li class="inline-block mr-1">
                <vs-button
                  :disabled="isCreating"
                  style="padding:5px 15px;"
                  @click="createOrUpdateHandler()">Save</vs-button>
              </li>
              <li style="display:inline-block">
                <vs-button
                  :disabled="isCreating"
                  style="padding:4px 15px;"
                  type="border"
                  @click="cancelHandler()">Cancel</vs-button>
              </li>
            </ul>
          </vs-col>
        </vs-row>
      </div>
      <vs-form autocomplete="off">
        <div class="custom-form py-4">
          <div class="py-4">
            <div class="pt-2 animate-pulse w-16" v-if="isFirstLoad">
              <div class="h-2 rounded bg-gray-400"></div>
            </div>
            <h6 class="font-bold" v-else>Tier package details</h6>
          </div>
          <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
            <vs-col vs-lg="4" vs-sm="8" vs-xs="12" v-if="isFirstLoad">
              <label class="custom-label animate-pulse">
                <div class="h-2 rounded bg-gray-400"></div>
              </label>
              <div class="p-4 pl-0">
                <div class="animate-pulse w-full">
                  <div class="grid grid-cols-4 gap-4">
                    <div class="col-span-2 h-2 rounded bg-gray-400"></div>
                    <div class="col-span-1 h-2 rounded bg-gray-400"></div>
                    <div class="col-span-1 h-2 rounded bg-gray-400"></div>
                  </div>
                </div>
              </div>
            </vs-col>
            <vs-col vs-lg="4" vs-sm="8" vs-xs="12" v-else>
              <vs-input :success="!errors.first('basic.name') && details.name != ''" val-icon-success="done"
                val-icon-danger="error" :danger="errors.first('basic.name')" v-validate="'required'"
                name="name" data-vv-as="Name" label="Name" class="custom-input w-full"
                data-vv-scope="basic" :danger-text="errors.first('basic.name')" v-model="details.name" />
            </vs-col>
          </vs-row>

          <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
            <vs-col class="mt-4" vs-lg="4" vs-sm="8" vs-xs="12" v-if="isFirstLoad">
              <label class="custom-label animate-pulse">
                <div class="h-2 rounded bg-gray-400"></div>
              </label>
              <div class="p-4 pl-0">
                <div class="animate-pulse w-full">
                  <div class="grid grid-cols-4 gap-4">
                    <div class="col-span-2 h-2 rounded bg-gray-400"></div>
                    <div class="col-span-1 h-2 rounded bg-gray-400"></div>
                    <div class="col-span-1 h-2 rounded bg-gray-400"></div>
                  </div>
                </div>
              </div>
            </vs-col>
            <vs-col vs-lg="4" vs-sm="8" vs-xs="12" v-else>
              <vs-input :success="!errors.first('basic.price') && details.price != ''" val-icon-success="done"
              style="margin-bottom: 0;"
                val-icon-danger="error" :danger="errors.first('basic.price')" v-validate="'required'"
                name="price" data-vv-as="GFE Consultation Fee" label="GFE Consultation Fee" class="custom-input w-full" data-vv-scope="basic"
                :danger-text="errors.first('basic.price')" v-model="details.price" />
            </vs-col>
          </vs-row>
        </div>
      </vs-form>
    </vs-card>

    <!-- products -->
    <vs-card>
      <div class="w-full" slot="header">
        <div v-if="isFirstLoad"
          class="flex items-center justify-between h-6">
          <div class="animate-pulse w-24">
            <div class="h-2 rounded bg-gray-400"></div>
          </div>
          <div class="animate-pulse w-24">
            <div class="grid grid-cols-3 gap-4">
              <div class="col-span-2 h-2 rounded bg-gray-400"></div>
              <div class="col-span-1 h-2 rounded bg-gray-400"></div>
            </div>
          </div>
        </div>
        <div v-else
          class="flex items-center justify-between">
          <p class="font-semibold lg:text-lg md:text-lg text-base">
            Products
          </p>
          <vs-button
            @click="handleSideBar(true)()"
            icon="add"
            type="border"
            class="lg:flex md:flex hidden"
          >
            Add product
          </vs-button>
          <vs-button
            @click="handleSideBar(true)()"
            type="border"
            class="lg:hidden md:hidden block"
          >
            Add
          </vs-button>
        </div>
      </div>
      <div v-if="!isFirstLoad"
        class="w-full">
        <div v-if="tags.length">
          <div
            v-for="(item, index) in tags"
            :key="index"
            class="flex select-none mt-1"
          >
            <p
              class="product-box py-2 px-4 rounded-lg ml-2 flex items-center"
            >
              {{ item.name }}
            </p>
          </div>
        </div>
        <div
          v-else
          class="lg:text-lg md:text-lg text-base select-none mt-1"
        >
          Add at least 1 product to get started
        </div>
      </div>
      <div v-else
        class="animate-pulse">
        <div class="flex-1 space-y-6 py-1">
          <div class="h-2 rounded bg-gray-400"></div>
          <div class="space-y-3">
            <div class="grid grid-cols-3 gap-4">
              <div class="col-span-2 h-2 rounded bg-gray-400"></div>
              <div class="col-span-1 h-2 rounded bg-gray-400"></div>
            </div>
            <div class="h-2 rounded bg-gray-400"></div>
          </div>
        </div>
      </div>
    </vs-card>

    <vs-sidebar
      class="sidebarx sidebar-list relative overflow-auto"
      parent="body"
      position-right
      click-not-close
      v-model="isOpen"
    >
      <!-- HEADER -->
      <div class="header space-y-3">
        <p class="text-xl font-semibold px-4 pt-4">Products</p>
        <vs-divider></vs-divider>
        <vs-input
          v-model="searchString"
          @input="handleSearch"
          :disabled="isSearching"
          placeholder="Search product"
          class="w-full pb-4 px-4"
        />
      </div>
      <!-- BODY -->
      <div class="scrollable-content">
        <div v-if="isSearching">Loading...</div>
        <div v-for="item in products" :key="item._id">
          <vs-checkbox
            class="custom-checkbox"
            v-model="selected"
            :vs-value="item._id"
            @input="handleInputChange"
          >
            <span>
              {{ item.name }}
            </span>
          </vs-checkbox>
        </div>
      </div>
      <!-- FOOTER -->
      <div class="footer flex flex-col gap-3" slot="footer">
        <vs-button
          @click="handleSave"
          :disabled="isSaving || isSearching">
          <vs-icon
            v-if="isSaving"
            icon="refresh"
            class="animate-spin"
          ></vs-icon>
          Save
        </vs-button>
        <vs-button type="border" @click.prevent="handleSideBar(false)()">
          Exit
        </vs-button>
      </div>
    </vs-sidebar>
    <!-- end product -->
  </div>
</template>

<script>
import { uniqBy, debounce } from "lodash";
import { mapActions } from "vuex";

export default {
  data() {
    return {
      isFirstLoad: true,
      isCreating: false,
      details: {
        name: "",
        price: null,
      },
      // side bar
      isSearching: false,
      isOpen: false,
      isSaving: false,
      selected: [],
      clonedSelected: [],
      tags: [],
      products: [],
    };
  },
  methods: {
    ...mapActions("product", ["fetchProducts"]),
    ...mapActions("tierPackage", [
      "getTierPackageDetails",
      "createTierPackage",
      "updateTierPackage"
    ]),
    async init() {
      try {
        if (this.isEditMode) {
          await this.fetchTierPackageDetails()
        }
        await this.getProducts();

        this.isFirstLoad = false;
      } catch (err) {
        console.log('Error initializing:: ', err.message)
      }
    },
    async getProducts(searchString = '') {
      this.products = []
      this.isSearching = true
      try {
        const res = await this.fetchProducts({
          limit: 100,
          search: searchString,
          sort: 'createdAt',
          dir: 'desc'
        })

        const formattedProducts = res.data.data.docs.map(({ _id, name }) => ({ _id, name }))

        this.products = formattedProducts
      } catch (err) {
        console.log('Error fetching products:: ', err.message)
      } finally {
        this.isSearching = false
      }
    },
    async fetchTierPackageDetails() {
      try {
        const { params } = this.$route
        const res = await this.getTierPackageDetails(params.id)
        const products = (res.data.data.products || [])

        this.details = res.data.data
        this.details.price = (res.data.data.gfeConsultationFee || 0) / 100
        this.selected = products.map(({ _id }) => (_id))
        this.tags = products
        this.clonedSelected = products
      } catch (err) {
        console.log('Error fetching tier package details:: ', err.message)
      }
    },
    cancelHandler() {
      this.$router.push({ name: 'SuperAdminTierPackageList' });
    },
    async createOrUpdateHandler() {
      this.$vs.loading();
      try {
        const isValid = await this.$validator.validate('basic.*');

        if (isValid) {
          if (!this.clonedSelected.length) {
            this.$vs.notify({
              title: "Unsuccessful",
              text: 'Please add at least 1 product to the tier package',
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger"
            });
            return
          }

          this.isCreating = true

          const body = {
            name: this.details.name,
            price: this.details.price,
            products: this.clonedSelected.map(({ _id }) => (_id))
          }

          let method = "createTierPackage"
          let payload = body

          if (this.isEditMode) {
            const { params } = this.$route;
            method = "updateTierPackage"
            payload = {
              id: params.id,
              body
            }
          }

          await this[method](payload)

          this.$vs.notify({
            title: "Success",
            text: "Tier package added successfully",
            color: "success",
          });

          this.cancelHandler()
        }
      } catch (err) {
        console.log('Error saving tier package:: ', err.message)

        this.$vs.notify({
          title: "Unsuccessful",
          text: 'Failed saving tier package.',
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger"
        });
      } finally {
        this.isCreating = false
        this.$vs.loading.close();
      }
    },
    handleSideBar(isOpen) {
      return () => {
        this.isOpen = isOpen
      }
    },
    handleInputChange(selected) {
      let matchSelected = []
      let clonedSelected = this.clonedSelected
      selected.map(id => {
        const matched = this.products.find(({ _id }) => _id === id)

        if (matched) matchSelected.push(matched)
      })

      clonedSelected = clonedSelected.filter(i => {
        const currentSearchMatched = this.products.find(({ _id }) => _id === i._id)
        const currentSelectedMatched = selected.find(id => id === i._id)

        return !currentSearchMatched && currentSelectedMatched
      })

      this.clonedSelected = uniqBy([
        ...clonedSelected,
        ...matchSelected
      ], '_id')
    },
    handleSearch: debounce(function (searchString) {
      this.getProducts(searchString)
    }, 1000),
    handleSave() {
      this.isSaving = true;

      if (this.selected.length) {
        this.tags = this.clonedSelected
      } else {
        this.tags = [];
      }

      this.isOpen = false;
      this.isSaving = false;
    },
  },
  created() {
    this.init()
  },
  computed: {
    mode() {
      return this.isEditMode ? 'Update' : 'Create'
    },
    isEditMode() {
      const { name } = this.$route;

      return name === 'SuperAdminTierPackageEdit'
    }
  }
};
</script>

<style lang="scss">
.tier-package-cont {
  .product-box {
    color: #ada8a8;
    background-color: #f0f0f0;
  }

  .custom-input {
    margin-bottom: 20px;
  }

  .custom-input label,
  .custom-label {
    display: block;
    padding: 5px 0;
    font-size: 12px;
  }
}

.vs-sidebar--background {
  z-index: 52000 !important;
}

.vs-sidebar-position-right {
  z-index: 53000 !important;
}

.sidebar-list .vs-sidebar-primary {
  display: flex;
  flex-direction: column;
  max-width: none;
}

.sidebar-list .custom-checkbox .vs-checkbox {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
}

.sidebar-list .vs-sidebar--items {
  overflow: hidden !important;
}

.sidebar-list .vs-sidebar--items .scrollable-content {
  overflow-y: auto;
  padding-top: 14px;
  padding-bottom: 14px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-left: 8px;
  height: calc(100% - 104px);
}

.sidebar-list .vs-sidebar--items .header {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 10;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
}

.sidebar-list .vs-sidebar--footer .footer {
  background-color: white;
  padding: 16px;
  z-index: 10;
  box-shadow: 0 -2px 2px rgba(0, 0, 0, 0.1);
}

.sidebarx {
  .vs-sidebar {
    max-width: 350px;

    &--items {
      overflow-y: auto;
      overflow-x: hidden !important;
    }
  }
}
</style>
